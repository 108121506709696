function trackSubmission(e) {
    const formHandle = e.target.form.config.formHandle;

    if (formHandle === null) {
        return;
    }

    if (formHandle == 'sweepstake') {
        try {
            // @ts-ignore
            _paq.push(['trackEvent', formHandle, formHandle + ' submitted']);
            console.log('[' + formHandle + '] track matomo submission');
            _mtm.push({ event: formHandle +'-submitted' });
            console.log('[' + formHandle + '] mtm push data layer');
        } catch {
            console.log('[' + formHandle + '] matomo not loaded');
        }
    }

    try {
        // @ts-ignore
        fbq('track', 'Lead');
        console.log('[' + formHandle + '] track fb lead');
    } catch {
        console.log('[' + formHandle + '] fb not loaded');
    }
}

export function initForm() {
    addEventListener('onAfterFormieSubmit', trackSubmission);
}
