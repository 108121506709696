export function initScrollFx() {
    const animEls = document.querySelectorAll('.js-scroll-fx');
    console.log('[scroll-fx] init', animEls.length, 'elements');

    const observer = new IntersectionObserver(
        (entries) => {
            // console.log('[scroll-fx] observer', entries.length, 'entries');
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // console.log('[scroll-fx] entry intersecting');
                    entry.target.classList.add('js-scroll-fx--active');
                } else {
                    // console.log('[scroll-fx] entry not intersecting');
                    entry.target.classList.remove('js-scroll-fx--active');
                }
            });
        },
        {
            threshold: 0.1,
        }
    );

    for (let i = 0; i < animEls.length; i++) {
        const elements = animEls[i];
        // console.log('[scroll-fx] observe element', elements);
        observer.observe(elements);
    }
}
